<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-6">
                <h1 class="display-3  text-white">
                  A Fully Mobile Personalised Solution
                  <!-- <span>completed with examples</span> -->
                </h1>
                <p class="lead  text-white">
                  BillMate is the solution to your living costs that allows you
                  to
                  <span class="display-4 text-white">Set and Forget</span> and
                  pay a single monthly amount, once a month for as long as you
                  choose to, adjustable only as a result of changes to your bill
                  amounts occur. A simple, easy and fast way to manage your cost
                  of living.
                </p>
                <div class="btn-wrapper">
                  <base-button
                    tag="a"
                    href="#"
                    class="mb-3 mb-sm-0"
                    type="white"
                    icon="fa fa-sign-in"
                  >
                    Register
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section section-lg pt-lg-0 mt--200">
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-lg-2 ml-lg-auto">
            <div class="position-relative pl-md-5">
              <img src="img/ill/ill-2.svg" class="img-center img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="ni ni-building"
                    size="lg"
                    class="bg-gradient-white"
                    color="primary"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-primary">Organise</h5>
                  <p>
                    BillMate is an innovative
                    <span class="text-primary" style="font-weight: bold;"
                      >Financial LIFEstyle</span
                    >
                    product designed to remove the stress and uncertainty of
                    regular bills.
                  </p>
                </div>
              </div>
            </card>
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="ni ni-satisfied"
                    gradient="success"
                    color="white"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-success">Control</h5>
                  <p>
                    By becoming a BillMate customer you have control in your
                    hands of how your living costs are managed, and can
                    determine how much of your living costs are linked to your
                    personal account.. Not only does BillMate offer an easy and
                    stress free bill management platform controlled by YOU, but
                    with your approval it can provide free bill comparisons new
                    and exciting provider offers.
                  </p>
                  <!-- <a href="#" class="text-success">Learn more</a> -->
                </div>
              </div>
            </card>
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="ni ni-active-40"
                    gradient="warning"
                    color="white"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-warning">Plan</h5>
                  <p>
                    Addition to these fantastic features if it offers
                    <span class="text-warning" style="font-weight: bold;"
                      >Forward Planning
                    </span>
                    features to plan savings targets and personal goals such as
                    buying a home or planning a holiday.
                  </p>
                  <!-- <a href="#" class="text-warning">Learn more</a> -->
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-3 bg-gradient-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container pt- pb-100">
        <div class="row row-grid justify-content-center">
          <div class="col-lg-8 text-center">
            <h2 class="display-3 text-white">
              Are you convinced about
              <span class="text-success">Billmate?</span>
            </h2>
            <!-- <p class="lead text-white">
              Join us today
            </p> -->
            <br>
            <div class="btn-wrapper">
              <a
                href="#"
                class="btn btn-primary mb-3 mb-sm-0"
                >Join Us Today</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>
