import Vue from "vue";
import VueRouter from "vue-router";
import Header from "./layout/AppHeader";
import Footer from "./layout/AppFooter";
import Landing from "./views/Landing.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    components: {
      header: Header,
      default: Landing,
      footer: Footer
    }
  }
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: "active",
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
